import * as React from "react"
import { Layout, SEO } from "../components"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { Box, CardActionArea, CardMedia, Grid } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import bannerImage from "./../images/bg.jpg"
import TextTransition, { presets } from "react-text-transition"
import CountUp from "react-countup"
import { useWindowSize } from "../utils"

const TEXTS = ["websites", "applications", "designs", "ui-interfaces"]

const useStyles = makeStyles({
  root: {
    display: "flex",
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  styledInfo: {
    color: "rgb(255, 193, 7)",
    fontSize: 25,
    fontWeight: "bolder",
  },
  simpleInfo: {
    marginLeft: "10px",
    marginTop: "-25px",
  },
  styledDiv: {
    backgroundColor: "rgba(25, 25, 35, 0.92)",
    height: "80px",
    width: "93%",
    margin: "auto",
  },
  styledCard: {
    marginTop: "-40px",
    backgroundImage: `url("${bannerImage}")`,
    transform: "scale(1)",
    opacity: 1,
    transition: "0.55s ease-in-out",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.2)",
    zIndex: "999",
    minHeight: "200px",
    // padding: "100px",
    color: "white",
    width: "100%",
  },
  media: {
    height: 140,
  },
  card: {
    margin: 6,
    minHeight: "230px",
    background:
      "linear-gradient( 159deg,rgba(37,37,50,0.98) 0%,rgba(35,35,45,0.98) 100% )",
    color: "white",
    boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.9)",
    padding: 10,
  },
  hr: {
    backgroundColor: "white",
  },
  question: {
    fontWeight: "bolder",
  },
  answer: {
    marginLeft: "10px",
    fontWeight: "200",
  },
})

const HomePage = () => {
  const classes = useStyles()
  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex(index => index + 1),
      3000 // every 3 seconds
    )
    return () => clearTimeout(intervalId)
  }, [])

  const { services, projects, personal } = useStaticQuery(
    graphql`
      query MyServicesAndProjects {
        services: allContentfulServices(limit: 3) {
          edges {
            node {
              id
              name
              description {
                data
              }
            }
          }
        }
        projects: allContentfulProjects(limit: 3) {
          edges {
            node {
              id
              description {
                description
              }
              name
              uri
              gitHubUri
              category
              images {
                id
                title
                file {
                  url
                }
              }
            }
          }
        }
        personal: allContentfulPersonalInformation {
          edges {
            node {
              experience
              awardsHonors
              clients
              projectsDone
              name
              country
              city
              age
              emails {
                development
                work
                school
              }
              languages {
                skills {
                  Backend
                  Frontend
                  CloudStack
                }
              }
              phoneNumbers {
                numbers
              }
              socials {
                Facebook
                Instagram
                GitHub
                LinkedIn
                Twitter
                WhatsApp
              }
              qualifications {
                School
                Diploma
                University
              }
              skills {
                skills
              }
              programmingLanguages {
                DevOps
                BackEnd
                Database
                Languages
                FrontEnd
                VersionSystem
              }
              street
              certifications {
                certificates
              }
              awards {
                awards
              }
            }
          }
        }
      }
    `
  )

  const personalInformation = personal.edges[0]
  const { width } = useWindowSize()

  return (
    <Layout title={"Home"}>
      <SEO title="Home Page" previewImage={{ width: 267 }} />
      <Box mt={2} ml={3} mr={3}>
        <Box>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            <div className={classes.styledDiv}></div>
            <Card className={`${classes.root} ${classes.styledCard}`}>
              <CardContent
                style={{
                  backgroundImage:
                    "linear-gradient(90deg, rgba(45, 45, 58, 0.9) 15%, rgba(45, 45, 58, 0.7) 50%, rgba(43, 43, 53, 0.7) 100%)",
                  width: "100%",
                  padding:
                    width > 425
                      ? "100px"
                      : width > 350
                      ? "48px 0 40px 30px"
                      : "40px 0 40px 25px",
                }}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  className="styled-font"
                  style={{
                    fontSize:
                      width > 2100
                        ? "60px"
                        : width > 425
                        ? "42px"
                        : width > 350
                        ? "35px"
                        : "30px",
                    fontWeight: "bolder",
                  }}
                >
                  Discover my Amazing <br /> Art Space!
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  className="styled-font-two"
                  style={{
                    fontSize:
                      width > 2100
                        ? "25px"
                        : width > 425
                        ? "16px"
                        : width > 350
                        ? "10px"
                        : "8px",
                    marginTop: "10px",
                  }}
                >
                  <span style={{ float: "left" }}>
                    {`<`}
                    <span style={{ color: "#FFC107" }}>code</span>
                    {`>`}
                  </span>
                  <span
                    style={{
                      float: "left",
                      marginRight: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    I build
                  </span>
                  <span style={{ float: "left" }}>
                    <TextTransition
                      text={TEXTS[index % TEXTS.length]}
                      springConfig={presets.wobbly}
                      inline
                    />
                  </span>
                  <span style={{ float: "left", marginLeft: "5px" }}>
                    {`</`}
                    <span style={{ color: "#FFC107" }}>code</span>
                    {`>`}
                  </span>
                  <br />
                </Typography>
                <Button
                  size={
                    width > 425 ? "large" : width > 350 ? "medium" : "small"
                  }
                  style={{
                    backgroundColor: "#FFC107",
                    padding: width > 425 ? "20px" : "15px",
                    fontSize:
                      width > 2100
                        ? "20px"
                        : width > 425
                        ? "16px"
                        : width > 350
                        ? "12px"
                        : "10px",
                    marginTop: "16px",
                  }}
                >
                  Explore now
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  className={classes.styledInfo}
                  style={{ fontSize: width > 2100 ? "35px" : "16px" }}
                >
                  <p>
                    <CountUp
                      delay={4}
                      duration={5}
                      start={0}
                      end={personalInformation.node.experience}
                      suffix="+"
                    />
                  </p>
                </Grid>
                <Grid
                  item
                  className={classes.simpleInfo}
                  style={{ fontSize: width > 2100 ? "20px" : "14px" }}
                >
                  Years Experience
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  className={classes.styledInfo}
                  style={{ fontSize: width > 2100 ? "35px" : "16px" }}
                >
                  <p>
                    {" "}
                    <CountUp
                      delay={4}
                      duration={5}
                      start={0}
                      end={personalInformation.node.projectsDone}
                      suffix="+"
                    />
                  </p>
                </Grid>
                <Grid
                  item
                  className={classes.simpleInfo}
                  style={{ fontSize: width > 2100 ? "20px" : "14px" }}
                >
                  Projects on Github
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  className={classes.styledInfo}
                  style={{ fontSize: width > 2100 ? "35px" : "16px" }}
                >
                  <p>
                    {" "}
                    <CountUp
                      delay={4}
                      duration={5}
                      start={0}
                      end={personalInformation.node.clients}
                      suffix="+"
                    />
                  </p>
                </Grid>
                <Grid
                  item
                  className={classes.simpleInfo}
                  style={{ fontSize: width > 2100 ? "20px" : "14px" }}
                >
                  Happy Clients
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  className={classes.styledInfo}
                  style={{ fontSize: width > 2100 ? "35px" : "16px" }}
                >
                  <p>
                    {" "}
                    <CountUp
                      delay={4}
                      duration={5}
                      start={0}
                      end={personalInformation.node.awardsHonors}
                      suffix="+"
                    />
                  </p>
                </Grid>
                <Grid
                  item
                  className={classes.simpleInfo}
                  style={{ fontSize: width > 2100 ? "20px" : "14px" }}
                >
                  Awards & Honors
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
          <Box mt={2} />
          <hr className={classes.hr} />
          <Box>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardContent style={{ padding: 20 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        style={{
                          fontSize:
                            width > 2100
                              ? "40px"
                              : width > 1024
                              ? "30px"
                              : width > 425
                              ? "24px"
                              : "18px",
                        }}
                        component="h2"
                      >
                        Hi! I'm {personalInformation.node.name} - aka JAZZ 👋.
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="h3"
                        style={{
                          fontSize:
                            width > 2100
                              ? "30px"
                              : width > 1024
                              ? "20px"
                              : width > 425
                              ? "19px"
                              : "16px",
                        }}
                      >
                        I'm a Coder, Gamer, Student, Developer and sometimes a
                        Teacher ! 😎😎
                      </Typography>
                      <br />
                      <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                      >
                        <Grid item>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="h4"
                            style={{
                              fontSize:
                                width > 2100
                                  ? "25px"
                                  : width > 425
                                  ? "17px"
                                  : "13px",
                            }}
                          >
                            Basic Information
                          </Typography>
                          <hr className={classes.hr} />
                          <Grid container direction="row" alignItems="center">
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "15px"
                                      : "12px",
                                }}
                              >
                                Country:
                                <span
                                  className={classes.answer}
                                  style={{
                                    fontSize:
                                      width > 2100
                                        ? "18px"
                                        : width > 425
                                        ? "13px"
                                        : "11px",
                                  }}
                                >
                                  {personalInformation.node.country}
                                </span>
                              </p>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "15px"
                                      : "12px",
                                }}
                              >
                                City:
                                <span
                                  className={classes.answer}
                                  style={{
                                    fontSize:
                                      width > 2100
                                        ? "18px"
                                        : width > 425
                                        ? "13px"
                                        : "11px",
                                  }}
                                >
                                  {personalInformation.node.city}
                                </span>
                              </p>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "15px"
                                      : "12px",
                                }}
                              >
                                Street:
                                <span
                                  className={classes.answer}
                                  style={{
                                    fontSize:
                                      width > 2100
                                        ? "18px"
                                        : width > 425
                                        ? "13px"
                                        : "11px",
                                  }}
                                >
                                  {personalInformation.node.street}
                                </span>
                              </p>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "15px"
                                      : "12px",
                                }}
                              >
                                Age:
                                <span
                                  className={classes.answer}
                                  style={{
                                    fontSize:
                                      width > 2100
                                        ? "18px"
                                        : width > 425
                                        ? "13px"
                                        : "11px",
                                  }}
                                >
                                  {personalInformation.node.age}
                                </span>
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="h4"
                            style={{
                              fontSize:
                                width > 2100
                                  ? "25px"
                                  : width > 425
                                  ? "17px"
                                  : "15px",
                            }}
                          >
                            Education background
                          </Typography>

                          <hr className={classes.hr} />
                          <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="strech"
                          >
                            <Grid item>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "15px"
                                      : "12px",
                                }}
                              >
                                School:
                                <span
                                  className={classes.answer}
                                  style={{
                                    fontSize:
                                      width > 2100
                                        ? "18px"
                                        : width > 425
                                        ? "13px"
                                        : "11px",
                                  }}
                                >
                                  {
                                    personalInformation.node.qualifications
                                      .School
                                  }
                                </span>
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "15px"
                                      : "12px",
                                }}
                              >
                                Diploma:
                                <span
                                  className={classes.answer}
                                  style={{
                                    fontSize:
                                      width > 2100
                                        ? "18px"
                                        : width > 425
                                        ? "13px"
                                        : "11px",
                                  }}
                                >
                                  {
                                    personalInformation.node.qualifications
                                      .Diploma
                                  }
                                </span>
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "15px"
                                      : "12px",
                                }}
                              >
                                University:
                                <span
                                  className={classes.answer}
                                  style={{
                                    fontSize:
                                      width > 2100
                                        ? "18px"
                                        : width > 425
                                        ? "13px"
                                        : "11px",
                                  }}
                                >
                                  {
                                    personalInformation.node.qualifications
                                      .University
                                  }
                                </span>
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="h4"
                            style={{
                              fontSize:
                                width > 2100
                                  ? "25px"
                                  : width > 425
                                  ? "17px"
                                  : "15px",
                            }}
                          >
                            Skills & Certifications
                          </Typography>
                          <hr className={classes.hr} />
                          <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="strech"
                          >
                            <Grid item>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "15px"
                                      : "12px",
                                }}
                              >
                                Technology Stack:
                                <span
                                  className={classes.answer}
                                  style={{
                                    fontSize:
                                      width > 2100
                                        ? "18px"
                                        : width > 425
                                        ? "13px"
                                        : "11px",
                                  }}
                                >
                                  {personalInformation.node.skills.skills.join(
                                    ", "
                                  )}
                                </span>
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "17px"
                                      : "15px",
                                }}
                              >
                                Programming Stack:
                                <Grid
                                  container
                                  direction="column"
                                  justify="space-evenly"
                                  alignItems="stretch"
                                  style={{ padding: "20px 20px 0 20px" }}
                                >
                                  <Grid item>
                                    <p
                                      className={classes.question}
                                      style={{
                                        fontSize:
                                          width > 2100
                                            ? "20px"
                                            : width > 425
                                            ? "15px"
                                            : "12px",
                                      }}
                                    >
                                      Front-End:
                                      <span
                                        className={classes.answer}
                                        style={{
                                          fontSize:
                                            width > 2100
                                              ? "18px"
                                              : width > 425
                                              ? "13px"
                                              : "11px",
                                        }}
                                      >
                                        {personalInformation.node.programmingLanguages.FrontEnd.join(
                                          ", "
                                        )}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid item>
                                    <p
                                      className={classes.question}
                                      style={{
                                        fontSize:
                                          width > 2100
                                            ? "20px"
                                            : width > 425
                                            ? "15px"
                                            : "12px",
                                      }}
                                    >
                                      Back-End:
                                      <span
                                        className={classes.answer}
                                        style={{
                                          fontSize:
                                            width > 2100
                                              ? "18px"
                                              : width > 425
                                              ? "13px"
                                              : "11px",
                                        }}
                                      >
                                        {personalInformation.node.programmingLanguages.BackEnd.join(
                                          ", "
                                        )}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid item>
                                    <p
                                      className={classes.question}
                                      style={{
                                        fontSize:
                                          width > 2100
                                            ? "20px"
                                            : width > 425
                                            ? "15px"
                                            : "12px",
                                      }}
                                    >
                                      Languages:
                                      <span
                                        className={classes.answer}
                                        style={{
                                          fontSize:
                                            width > 2100
                                              ? "18px"
                                              : width > 425
                                              ? "13px"
                                              : "11px",
                                        }}
                                      >
                                        {personalInformation.node.programmingLanguages.Languages.join(
                                          ", "
                                        )}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid item>
                                    <p
                                      className={classes.question}
                                      style={{
                                        fontSize:
                                          width > 2100
                                            ? "20px"
                                            : width > 425
                                            ? "15px"
                                            : "12px",
                                      }}
                                    >
                                      Databases:
                                      <span
                                        className={classes.answer}
                                        style={{
                                          fontSize:
                                            width > 2100
                                              ? "18px"
                                              : width > 425
                                              ? "13px"
                                              : "11px",
                                        }}
                                      >
                                        {personalInformation.node.programmingLanguages.Database.join(
                                          ", "
                                        )}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid item>
                                    <p
                                      className={classes.question}
                                      style={{
                                        fontSize:
                                          width > 2100
                                            ? "20px"
                                            : width > 425
                                            ? "15px"
                                            : "12px",
                                      }}
                                    >
                                      Version Control:
                                      <span
                                        className={classes.answer}
                                        style={{
                                          fontSize:
                                            width > 2100
                                              ? "18px"
                                              : width > 425
                                              ? "13px"
                                              : "11px",
                                        }}
                                      >
                                        {personalInformation.node.programmingLanguages.VersionSystem.join(
                                          ", "
                                        )}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid item>
                                    <p
                                      className={classes.question}
                                      style={{
                                        fontSize:
                                          width > 2100
                                            ? "20px"
                                            : width > 425
                                            ? "15px"
                                            : "12px",
                                      }}
                                    >
                                      DevOps:
                                      <span
                                        className={classes.answer}
                                        style={{
                                          fontSize:
                                            width > 2100
                                              ? "18px"
                                              : width > 425
                                              ? "13px"
                                              : "11px",
                                        }}
                                      >
                                        {personalInformation.node.programmingLanguages.DevOps.join(
                                          ", "
                                        )}
                                      </span>
                                    </p>
                                  </Grid>
                                </Grid>
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "15px"
                                      : "12px",
                                }}
                              >
                                Achievements:
                                <Grid
                                  container
                                  direction="column"
                                  justify="space-evenly"
                                  alignItems="stretch"
                                  style={{ padding: "20px 20px 0 20px" }}
                                >
                                  {personalInformation.node.awards.awards.map(
                                    (award, index) => (
                                      <Grid item key={index}>
                                        <span
                                          className={classes.answer}
                                          style={{
                                            fontSize:
                                              width > 2100
                                                ? "18px"
                                                : width > 425
                                                ? "13px"
                                                : "11px",
                                          }}
                                        >
                                          {award}
                                        </span>
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                className={classes.question}
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 425
                                      ? "17px"
                                      : "15px",
                                }}
                              >
                                Certifictions:
                                <Grid
                                  container
                                  direction="column"
                                  justify="space-evenly"
                                  alignItems="stretch"
                                  style={{ padding: "20px 20px 0 20px" }}
                                >
                                  {personalInformation.node.certifications.certificates.map(
                                    (certificate, index) => (
                                      <Grid item key={index}>
                                        <span
                                          className={classes.answer}
                                          style={{
                                            fontSize:
                                              width > 2100
                                                ? "18px"
                                                : width > 425
                                                ? "13px"
                                                : "11px",
                                          }}
                                        >
                                          {certificate}
                                        </span>
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </p>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "22px"
                                      : width > 425
                                      ? "17px"
                                      : "14px",
                                }}
                                component="h6"
                              >
                                I want to learn everything because I am ONE
                                DEVELOPER ARMY 😎 😎
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography gutterBottom variant="h5" component="h2">
                        {/* {name} */}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {/* <p
                          style={{ paddingTop: 10, paddingBottom: 7 }}
                          dangerouslySetInnerHTML={{
                            __html: description.description,
                          }}
                        ></p> */}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="h5" component="h4">
            My Services
          </Typography>
          <Box mt={2} />
          <hr className={classes.hr} />
          <Box>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              {services ? (
                services.edges.map(({ node: { id, name, description } }) => {
                  return (
                    <Grid key={id} item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Card
                        className={classes.card}
                        style={{ minHeight: width > 768 ? "230px" : "150px" }}
                      >
                        <CardContent>
                          <Typography
                            variant="h5"
                            style={{
                              fontSize: width > 2100 ? "34px" : "20px",
                              fontWeight: "bold",
                            }}
                            component="h2"
                          >
                            {name}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body2" component="p">
                              <p
                                style={{
                                  paddingTop: 20,
                                  paddingBottom: 7,
                                  fontSize:
                                    width > 2100
                                      ? "20px"
                                      : width > 1024
                                      ? "18px"
                                      : "12px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: description.data,
                                }}
                              ></p>
                            </Typography>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                })
              ) : (
                <p>No services</p>
              )}
            </Grid>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="h5" component="h4">
            My Projects
          </Typography>
          <Box mt={2} />
          <hr className={classes.hr} />
          <Box>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              {projects ? (
                projects.edges.map(
                  ({
                    node: {
                      id,
                      name,
                      description,
                      uri,
                      gitHubUri,
                      images,
                      category,
                    },
                  }) => {
                    return (
                      <Grid key={id} item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className={classes.card}>
                          <CardActionArea>
                            {images && (
                              <CardMedia
                                className={classes.media}
                                image={`https://${images[0].file.url}`}
                                title={images[0].title}
                              />
                            )}
                            <CardContent>
                              <Typography
                                className={classes.title}
                                gutterBottom
                                style={{
                                  fontSize:
                                    width > 2100
                                      ? "18px"
                                      : width > 768
                                      ? "14px"
                                      : "12px",
                                }}
                              >
                                {category}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                                style={{
                                  fontSize: width > 2100 ? "34px" : "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                {name}
                              </Typography>
                              <Typography variant="body2" component="p">
                                <p
                                  style={{
                                    paddingTop: 10,
                                    paddingBottom: 7,
                                    fontSize:
                                      width > 2100
                                        ? "21px"
                                        : width > 768
                                        ? "18px"
                                        : "14px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: description.description,
                                  }}
                                ></p>
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                            <Button
                              size={width > 768 ? "large" : "medium"}
                              style={{ backgroundColor: "rgb(255, 193, 7)" }}
                            >
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                href={gitHubUri}
                              >
                                Github
                              </a>
                            </Button>
                            <Button
                              size={width > 768 ? "large" : "medium"}
                              style={{ backgroundColor: "rgb(255, 193, 7)" }}
                            >
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                href={uri}
                              >
                                Show Demo
                              </a>
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    )
                  }
                )
              ) : (
                <p>No services</p>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default HomePage
